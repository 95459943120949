import React, { useState, type ReactElement } from "react";
// import OTPInputGroup from "../otpInput/otpInputs";
import styles from "./login.module.scss";
import { login } from "../../services/auth.aws.service";
import { alertService } from "../../services/alert.service";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "@mui/material";
import isEmail from 'validator/lib/isEmail';
export interface loginProps {
  prop?: string;
}

function Login(prop: loginProps): ReactElement {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleClick = async () => {
    if (!isEmail(email)){ 
      console.log(isEmail(email))
      setErrorMsg("Please Enter a Valid Email");
      setLoading(false);
      return;
    };
    setLoading(true);

    try {
      await login("email", { email }, () => {});
      alertService.success("Successfully Sent Email to Verify", { id: "top" });
      navigate(encodeURI(`/otp-verify?email=${email}`));
      setLoading(false);
    } catch (err) {
      alertService.error(err.message, { id: "top" });
    }
  };
  // alertService.success('Successfully Sent Email to Verify',{id: 'top'});
  return (
    <div className={styles.overlay}>
      <p className={styles.bettics}>
        Bettics<span className={styles.dot}>.</span>
      </p>

      <div className={styles.loginContainer}>
        <p className={styles.loginMsg}>Login to your account</p>
        <div className={styles.email}>
          <p className={styles.entere}>Enter your email</p>
          <TextField
            error={errorMsg !== ""}
            id="outlined-error-helper-text"
            value={email}
            placeholder="E-mail"
            onChange={(e) =>{ setEmail(e.target.value); setErrorMsg("")}}
            helperText={errorMsg}
          />
          <br />
        </div>
        <LoadingButton
          variant="contained"
          className={styles.login}
          onClick={handleClick}
          loading={loading}
        >
          Continue with Email
        </LoadingButton>
        {/* {isPopupOpen && <OTPInputGroup />} */}
      </div>
    </div>
  );
}

export default Login;
