import React from 'react';

import styles from './Loading.module.scss';

export interface LoadingProps {
  prop?: string;
}

export function Loading({prop = 'default value'}: LoadingProps) {
  return <div className={styles['loading-wrapper']}>
  <div className={styles['loading-text']}>LOADING</div>
  <div className={styles['loading-content']}></div>
</div>;
}
