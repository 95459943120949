// players.tsx

import React, { useEffect, useState } from "react";
import styles from "./players.module.scss";
import { Loading } from "../../components";
import { columnHeadersPlayer } from "./constants";
import { fetchFilters, fetchPlayers, fetchPlayerDropdown } from "../../services/players.service";
import { useDispatch } from "react-redux";

import { TableComponent } from "../../components/TableComponent";
import { addPicks, openPicks } from "../../store/mypicks.slice";
import MyPicks from "../my-picks/mypicks";
import { SelectChangeEvent } from '@mui/material';
import CustomSelect from "./dropdown";

interface PlayerProps {
  prop?: any;
}

function Players(prop: PlayerProps): React.JSX.Element {
  const dispatch = useDispatch();
  const [Pdata, setPData] = useState([]);
  const [PdataFetch, setPDataFetch] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [hasMore, setHasMore] = useState(true);
  const [playersList, setPlayersList] = useState<string[]>([]);
  const [marketsList, setMarketsList] = useState<string[]>([]);
  const [outcomesList, setOutcomesList] = useState<string[]>(['over','under']);
  const [schedulesList, setSchedulesList] = useState<any[]>([]);
  const [selectedPlayers, setSelectedPlayers] = useState<string[]>([]);
  const [selectedMarkets, setSelectedMarkets] = useState<string[]>([]);
  const [selectedOutcomes, setSelectedOutcomes] = useState<string>('');
  const [selectedSchedules, setSelectedSchedules] = useState<string[]>([]);
  const [l5, setL5] = useState<string>("desc");
  const [l10, setL10] = useState<string>("desc");
  const [lSeason, setLSeason] = useState<string>("desc");
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(()=>{
    setPData([])
    setHasMore(true)
    setCurrentPage(1)
  },[l5,l10,lSeason]);

  useEffect(() => {
    // Fetch player names and market names
    fetchFilters()
      .then((data) => {
        setPlayersList(data.players);
        setMarketsList(data.markets);
        setSchedulesList(data.schedules);
      })
      .catch((error) => console.error("Error fetching filters:", error));
  }, []);

  const handlePlayerChange = (event: SelectChangeEvent<string[]>) => {
    setPData([])
    setHasMore(true)
    setCurrentPage(1)
    setSelectedPlayers(event.target.value as string[]);
  };

  const handleMarketChange = (event: SelectChangeEvent<string[]>) => {
    setPData([])
    setHasMore(true)
    setCurrentPage(1)
    setSelectedMarkets(event.target.value as string[]);
  };

  const handleOutcomesChange = (event: SelectChangeEvent<string>) => {
    setPData([])
    setHasMore(true)
    setCurrentPage(1)
    setSelectedOutcomes(event.target.value as string);
  };
  
  const handleScheduleChange = (event: SelectChangeEvent<string | string[]>) => {
    setPData([]); // Reset data
    setHasMore(true); // Reset hasMore
    setCurrentPage(1); // Reset currentPage
    const value = event.target.value as string | string[];
    setSelectedSchedules(Array.isArray(value) ? value : [value]);
  };


  const handleClearPlayers = () => {
    setPData([])
    setCurrentPage(1)
    setHasMore(true)
    setSelectedPlayers([]); // Clear selected players
  };

  const handleClearMarkets = () => {
    setPData([])
    setCurrentPage(1)
    setHasMore(true)
    setSelectedMarkets([]); // Clear selected markets
  };

  const handleClearOutcomes = () => {
    setPData([])
    setCurrentPage(1)
    setHasMore(true)
    setSelectedOutcomes(''); // Clear selected markets
  };

  const handleClearSchedules = () => {
    setPData([])
    setCurrentPage(1)
    setHasMore(true)
    setSelectedSchedules([]); // Clear selected markets
  }; 

  useEffect(() => {
    setPDataFetch(true)
    fetchPlayers(currentPage, selectedPlayers, selectedMarkets, selectedOutcomes, selectedSchedules, l5, l10, lSeason, (data: any, hasMoreData: boolean) => {
      setPData((prevData) => [...prevData, ...data]);
      setPDataFetch(false);
      setHasMore(hasMoreData);
        // Update hasMore based on the API response
    });
  }, [l5, l10, lSeason]);

  useEffect(() => {
    if(!initialLoad){
      setPDataFetch(true)
      fetchPlayerDropdown(selectedPlayers, selectedMarkets, selectedOutcomes, selectedSchedules, (data: any, hasMoreData: boolean) => {
        setPData((prevData) => [...prevData, ...data]);
        setPDataFetch(false);
        setHasMore(hasMoreData);
        setL5("desc");
        setL10("desc");
        setLSeason("desc");
          // Update hasMore based on the API response
      });
    }
  }, [selectedMarkets, selectedPlayers, selectedOutcomes, selectedSchedules]);

  useEffect(() => {
    if (hasMore) {
      setPDataFetch(true)
      fetchPlayers(currentPage, selectedPlayers, selectedMarkets, selectedOutcomes, selectedSchedules, l5, l10, lSeason, (data: any, hasMoreData: boolean) => {
        setPData((prevData) => [...prevData, ...data]);
        setPDataFetch(false);
        setHasMore(hasMoreData);
        if(initialLoad) setInitialLoad(false);
         // Update hasMore based on the API response
      });
    }
  }, [currentPage]);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    // Check if the user has scrolled to the bottom
    if (scrollTop + clientHeight >= scrollHeight - 10 && !PdataFetch && hasMore) {
      setHasMore(true)
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [PdataFetch, hasMore]);

  return (
    <div className={styles.match}>
      <CustomSelect
        label="Players"
        selectedValues={selectedPlayers}
        handleChange={handlePlayerChange}
        handleClear={handleClearPlayers}
        itemList={playersList}
        multiple={true}
        flag={1}
      />
      <CustomSelect
        label="Markets"
        selectedValues={selectedMarkets}
        handleChange={handleMarketChange}
        handleClear={handleClearMarkets}
        itemList={marketsList}
        multiple={true}
        flag={1}
      />
      <CustomSelect
        label="Outcomes Type"
        selectedValues={selectedOutcomes}
        handleChange={handleOutcomesChange}
        handleClear={handleClearOutcomes}
        itemList={outcomesList}
        width="200px"
        multiple={false}
        flag={2}
      />
      <CustomSelect
        label="Games"
        selectedValues={selectedSchedules}
        handleChange={handleScheduleChange}
        handleClear={handleClearSchedules}
        itemList={schedulesList.map((schedule) => ({
          value: schedule._id,
          label: `${schedule.competitors[0].abbreviation} VS ${schedule.competitors[1].abbreviation}`,
        }))}
        multiple={true}
        flag={3}
      />

      <MyPicks />
      <TableComponent
        columnHeaders={columnHeadersPlayer}
        rows={Pdata}
        fetch={!PdataFetch}
        setPicks={(pick) => {
          dispatch(addPicks(pick));
          dispatch(openPicks());
        }}
        l5={l5}
        setL5={setL5}
        l10={l10}
        setL10={setL10}
        lSeason={lSeason}
        setLSeason={setLSeason}
      />
    </div>
  );
}


export default Players;
