import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

import Toolbar from "@mui/material/Toolbar";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import books from "../../assets/png/books";
import CardContent from "@mui/material/CardContent";
import { Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deletePick, closePicks, openPicks, selectBook } from "../../store/mypicks.slice";
import { DRAFTKING_URL, FANDUEL_URL, MGM_URL } from "../matchups/constants";
const drawerWidth = 400;
import Fab from "@mui/material/Fab";
import FavoriteIcon from "@mui/icons-material/Favorite";

const MyPicks = ({ }) => {
  const picks = useSelector((state: any) => state?.mypicks?.picks);
  const selectedBook = useSelector((state: any) => state?.mypicks?.selectedBook)
  const isOpenPicks = useSelector((state: any) => state?.mypicks?.isOpenPicks);
  const [exterEventUrl, setExternalUrl] = useState("");
  const dispatch = useDispatch();

  const handleFanduelPlayerEvent = (event, index) => {
    let generatedParams = "";
    if (index === 0) {
      const param = `?marketId[${index}]=${event?.external_market_id}&selectionId[${index}]=${event?.external_outcome_id}`;
      generatedParams += param;
    } else {
      const param = `&marketId[${index}]=${event?.external_market_id}&selectionId[${index}]=${event?.external_outcome_id}`;
      generatedParams += param;
    }
    return generatedParams;
  };

  const handleDraftKingsPlayerEvent = (event, index) => {
    let generatedParams = '';

    if (index === 0) {
      const param = `?outcomes=${event?.external_outcome_id}`;
      generatedParams += param;
    } else {
      const param = `+${event?.external_outcome_id}`;
      generatedParams += param;
    }

    return generatedParams
  };

  const handleMgmPlayerEvent = (event, index) => {
    let generatedParams = '';

    if (index === 0) {
      const param = `?options=${event?.external_sport_event_id}-${event?.external_market_id}-${event?.external_outcome_id}`;
      generatedParams += param;
    } else {
      const param = `,${event?.external_sport_event_id}-${event?.external_market_id}-${event?.external_outcome_id}`;
      generatedParams += param;
    }

    return generatedParams
  };

  const handleFanduelTeamEvent = (event, index) => {
    let generatedParams = "";

    if (index === 0) {
      const param = `?marketId[${index}]=${event?.external_market_id}&selectionId[${index}]=${event
        ?.outcomes[event?.teamType]?.external_outcome_id}`;
      generatedParams += param;
    } else {
      const param = `&marketId[${index}]=${event?.external_market_id}&selectionId[${index}]=${event
        ?.outcomes[event?.teamType]?.external_outcome_id}`;
      generatedParams += param;
    }

    return generatedParams;
  };

  const handleFanduelEvent = (events) => {
    let generatedParams = "";

    events?.forEach((event, index) => {
      generatedParams += event?.isTeam
        ? handleFanduelTeamEvent(event, index)
        : handleFanduelPlayerEvent(event, index);
    });

    setExternalUrl(FANDUEL_URL + generatedParams);
  };

  const handleDraftKingsTeamEvent = (event, index) => {
    let generatedParams = ""
    if (index === 0) {
      const param = `?outcomes=${event?.outcomes[event?.teamType]
        ?.external_outcome_id}`;
      generatedParams += param;
    } else {
      const param = `+${event?.outcomes[event?.teamType]
        ?.external_outcome_id}`;
      generatedParams += param;
    }
    return generatedParams
  }

  const handleDraftKingsEvent = (events) => {
    console.log("events", events);
    let generatedParams = `/${events[0]?.external_sport_event_id}`;

    events?.forEach((event, index) => {
      generatedParams += event?.isTeam ? handleDraftKingsTeamEvent(event, index) : handleDraftKingsPlayerEvent(event, index)
    });

    setExternalUrl(DRAFTKING_URL + generatedParams);
  };

  const handleMgmTeamEvent = (event, index) => {
    let generatedParams = ""
    if (index === 0) {
      const param = `?options=${event?.external_sport_event_id}-${event?.external_market_id}-${event
        ?.outcomes[event?.teamType]?.external_outcome_id}`;
      generatedParams += param;
    } else {
      const param = `,${event?.external_sport_event_id}-${event?.external_market_id}-${event
        ?.outcomes[event?.teamType]?.external_outcome_id}`;
      generatedParams += param;
    }
    return generatedParams
  }

  const handleMgmEvent = (events) => {
    let generatedParams = `/${events[0]?.external_sport_event_id}`;

    events?.forEach((event, index) => {
      generatedParams += event?.isTeam ? handleMgmTeamEvent(event, index) : handleMgmPlayerEvent(event, index)
    });

    setExternalUrl(MGM_URL + generatedParams);
  };

  const handleOpenBookPlayerEvent = () => {
    const bookEvents = [];

    for (let pick of picks) {
      const requiredBook = pick?.books?.find((b) => b?.book_name === selectedBook);
      if (requiredBook) bookEvents.push(requiredBook);
    }

    if (selectedBook === "FanDuel") handleFanduelEvent(bookEvents);
    else if (selectedBook === "DraftKings") handleDraftKingsEvent(bookEvents);
    else if (selectedBook === "MGM") handleMgmEvent(bookEvents);
  };

  const handleOpenBookEvent = () => {
    const bookEvents = [];
    for (let pick of picks) {
      let requiredBook;
      if (!pick?.isAvailable) continue

      if (pick?.isTeam) {
        requiredBook = pick?.books?.find((b) => b?.name === selectedBook);
      }
      else {
        requiredBook = pick?.books?.find((b) => b?.book_name === selectedBook);
      }

      if (!requiredBook) continue

      let obj = pick?.isTeam
        ? { ...requiredBook, ...{ teamType: pick?.teamType, isTeam: true } }
        : requiredBook;
      bookEvents.push(obj);
    }

    if (selectedBook === "FanDuel") handleFanduelEvent(bookEvents);
    else if (selectedBook === "DraftKings") handleDraftKingsEvent(bookEvents);
    else if (selectedBook === "MGM") handleMgmEvent(bookEvents);
  };

  useEffect(() => {
    if (picks.length === 0) {
      setExternalUrl("");
      return;
    }

    handleOpenBookEvent();
  }, [selectedBook, picks]);

  const handleRemovePick = (id) => {
    dispatch(deletePick(id));
  };
  return (
    <>
      <Fab
        onClick={() => dispatch(openPicks())}
        aria-label="like"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        <FavoriteIcon />
      </Fab>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            backgroundColor: "#262626",
          },
        }}
        variant="persistent"
        anchor="right"
        open={isOpenPicks}
      // open={openDwawer}
      >
        <Toolbar style={{ color: "#fff", fontWeight: "bold" }}>
          My Picks
          <IconButton
            aria-label="delete"
            style={{ color: "#fff" }}
            onClick={() => dispatch(closePicks())}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Divider style={{ borderColor: "#fff" }} />
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedBook}
          label="Age"
          MenuProps={{
            sx: {
              '& ul': {
                padding: 0,
              },
              '& li': {
                padding: 0,
              },
            }
          }}
          onChange={(e) => {
            dispatch(selectBook(e.target.value as string));
          }}
        >
          {["FanDuel", "DraftKings", "BetRivers", "MGM"].map((text, index) => (
            <MenuItem
              key={text}
              className="custom-menu-item-class"
              style={{
                display: "flex",
                padding: "10px 16px",
                backgroundColor: "#222222",
              }}
              value={text}
            >
              <div style={{ display: "flex" }}>
                <ListItemIcon
                  style={{
                    alignSelf: "center",
                    minWidth: "fit-content",
                    marginRight: "10px ",
                    backgroundColor: "#222222",
                  }}
                >
                  <img width={18} height={18} src={books[text]} alt="Icon" />
                </ListItemIcon>
                <ListItemText style={{ color: "#fff" }} primary={text} />
              </div>
            </MenuItem>
          ))}
        </Select>

        <Divider style={{ borderColor: "#fff" }} />
        <List style={{ height: "70%", overflowY: "auto" }}>
          {picks?.map((pick, index) => (
            // <div style={{color:"#fff"}}>{JSON.stringify(pick)}</div>
            <ListItem key={index}>
              <Card
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  backgroundColor: "#5e5e5e",
                }}
              >
                <CardContent sx={{ flex: "1 0 auto", color: "#fff" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography component="div" variant="h6">
                      {pick?.market}
                    </Typography>
                    {!pick?.isAvailable && (
                      <Typography color="red" fontSize={14}>
                        Not Avaialble
                      </Typography>)}
                    <IconButton
                      aria-label="delete"
                      style={{ color: "#fff" }}
                      onClick={() => handleRemovePick(pick?.id)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    {pick.subject}
                  </Typography>
                </CardContent>
                <Box
                  sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
                >
                  {pick?.books?.map((ele) => {
                    return pick?.isTeam ? (
                      <ListItem
                        style={{}}
                        onClick={(e) => {
                          e.stopPropagation();
                          console.log("ele", ele);
                        }}
                      >
                        <ListItemButton>
                          <ListItemIcon
                            style={{
                              alignSelf: "center",
                              minWidth: "fit-content",
                              marginRight: "10px ",
                            }}
                          >
                            <img
                              width={18}
                              height={18}
                              src={books[ele.name]}
                              alt="Icon"
                            />
                          </ListItemIcon>

                          <ListItemText
                            style={{ color: "#fff" }}
                            primary={
                              ele?.outcomes[pick?.teamType]?.odds_american
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    ) : (
                      <ListItem
                        style={{}}
                        onClick={(e) => {
                          e.stopPropagation();
                          console.log("ele", ele);
                        }}
                      >
                        <ListItemButton>
                          <ListItemIcon
                            style={{
                              alignSelf: "center",
                              minWidth: "fit-content",
                              marginRight: "10px ",
                            }}
                          >
                            <img
                              width={18}
                              height={18}
                              src={books[ele.book_name]}
                              alt="Icon"
                            />
                          </ListItemIcon>

                          <ListItemText
                            style={{ color: "#fff" }}
                            primary={ele?.odds_american}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </Box>
              </Card>
            </ListItem>
          ))}
        </List>
        <a
          style={{
            width: "100%",
            backgroundColor: "#FF7448",
            padding: "1rem",
            color: "#fff",
            position: "absolute",
            bottom: 0,
            pointerEvents: exterEventUrl ? "auto" : "none",
            textDecoration: "none",
            textAlign: "center",
            borderRadius: "2px",
          }}
          target="_blank"
          rel="noopener noreferrer"
          href={exterEventUrl}
        >
          Bet Picks
        </a>
      </Drawer>
    </>
  );
};
export default MyPicks;
