import React, { Suspense } from "react";
import { Loading } from "../Loading";
interface RenderTableProps{
  rows: [];
  columnHeaders: any[];
  fetch: boolean,
  openPicks?: any,
  setPicks?: any
  setPicksV2?: any
}

export function RenderTableBody({rows, columnHeaders,fetch, openPicks, setPicks, setPicksV2}: RenderTableProps) {
    return (
      <tbody>
        {!fetch && <Loading/>}
        {rows?.map((row: any, index: number) => (
          <tr key={index}>
           
            {columnHeaders.map((cell: any, index: number) => {
              if (typeof cell.key === "function")
                return cell.key(row,index,setPicks,openPicks,setPicksV2) ;
              return (<td key={index}>{row[cell.key]}</td>);
            })}
          </tr>
        ))}
      </tbody>
    );
  }