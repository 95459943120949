"use client"
import React from 'react';
import styles from "./Nb.module.scss"

export interface NbProps {
  prop?: string;
}
export const Nb = () => {
  return (
    <nav className={styles.navbar}>
      <div className={styles["navbar-left"]}>
        <span className={styles["company-name"]}>Bettics</span>
        </div>
      <div className={styles["navbar-right"]}>
       <span style={{cursor:'pointer'}} onClick={()=>{
        localStorage.clear()
        sessionStorage.clear()
        window.location.reload()
       }}>logout</span> 
        <span className={styles.icon}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1330_3082)">
<path d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_1330_3082">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
</span>
<span className={styles.icon}>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="32" width="32" height="32" rx="4" transform="rotate(-90 0 32)" fill="#262626"/>
<path d="M8.42797 21.9993C2.16632 21.9974 9.99351 19.2566 9.99351 15.5997C9.99351 11.9427 2.1663 9.2 8.42797 9.2C14.6896 9.2 18.9956 9.2 24.0834 9.2C29.1712 9.2 22.5179 11.4856 22.5179 15.5997C22.5179 19.7137 29.172 22.0004 24.0834 21.9993C18.9949 21.9983 14.6896 22.0012 8.42797 21.9993Z" fill="white"/>
<path d="M16.4 14.3999C17.7255 14.3999 18.8 14.2745 18.8 15.5999C18.8 16.9254 17.7255 16.7999 16.4 16.7999C15.0745 16.7999 14 16.9254 14 15.5999C14 14.2745 15.0745 14.3999 16.4 14.3999Z" fill="#262626"/>
</svg>
</span>
      </div>
    </nav>
  );
}

