import React, { useEffect, useState, useContext } from "react";
import styles from './Team.module.scss';
import data from '../../services/teamData';

type Player = {
  name: string;
  image: string;
  team: any;
};

const Team = () => {
  const groupedData = data.data.reduce((groups:any, item) => {
    const team = item.team;
    if (!groups[team]) {
      groups[team] = [];
    }
    groups[team].push(item);
    return groups;
  }, {});

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <div className={styles.arrowBtn}>
          <span>
            <svg
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 14L8.41 12.59L3.83 8H20V6H3.83L8.42 1.41L7 0L0 7L7 14Z"
                fill="white"
                className={styles.svg}
              />
            </svg>
            &nbsp; Main Table
          </span>
        </div>

        <div>
          <span className={styles.sport}>NBA</span>
        </div>
      </div>

      <div className={styles.squads}>
       
        {Object.entries(groupedData as { [team: string]: Player[] }).map(
          ([team, players]) => (
            <div className={styles.squad} key={team}>
              <p className={styles.teamName}>{team}</p>
              <div className={styles.box}>
                {players.map((player) => (
                  <div key={player.name} className={styles.item}>
                    <span>
                      <img
                        src={player.image}
                        alt='player image'
                        className={styles.img}
                      ></img>
                    </span>
                    <p className={styles.pname}>{player.name}</p>
                    <p className={styles.guard}>guard</p>
                  </div>
                ))}
              </div>
            </div>
          )
        )}

        
      </div>
    </div>
  );
};

export default Team;
