import React, { Component } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import matchups from "../pages/matchups";
import login from "../pages/login";
import insights from "../pages/insights";
import news from "../pages/news";
import ProtectedRoute from "./ProtectedRoute";
import OTPInputGroup from "../pages/otp-verify/otp-verify";
import Players from "../pages/players";
import teams from "../pages/teams";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute />}>
      <Route path="/" element={<Navigate to="matchups" />}></Route>
        <Route index path="matchups" Component={matchups}></Route>
        <Route path="players" Component={Players}></Route>
        <Route path="team" Component={teams}></Route>
        <Route path="insights" Component={insights}></Route>
      </Route>
      <Route path="/news" Component={news}></Route>
      <Route path="/login" Component={login}></Route>
      <Route path="/otp-verify" Component={OTPInputGroup}></Route>
      <Route path="/404" Component={<>Page Not Found</>}></Route>
    </Routes>
  );
};
