import React, { } from "react";
import Fanduel from "../../assets/png/books/FanDuel.png";
import moment from 'moment';
import books from "../../assets/png/books";
import _ from "lodash";
import styles from "./players.module.scss";


const Image_URL = "https://images.bettics.win/nba/team/"
const PlayerImage_URL = "https://images.bettics.win/nba/player/"


const formatPicks = (market, cell) => {
  return {
    id: cell?._id,
    market: market,
    teamType: 0,
    subject: cell?.player_name,
    books: cell?.book_details
  }
}


const calculateSpread = (odd) => {
  return ((odd + 100) / odd);
}

export function DateFormatter(cell: any, index: number) {
  return <td key={index}><>
    <b>{moment(cell.start_time).format('MMMM Do')}</b><br />
    <time>{moment(cell.start_time).format('h:mm A')}</time>
  </>
  </td>
}

export function UnderFormatter(cell: any, index: number) {
  // let market = cell?.book_details.find((ele) => ele.id == "sr:market:225")

  let max_val_MoneyLine = _.max(cell?.book_details.map(ele => ele?.odds_american));
  let selected_book = cell?.book_details.find(ele => ele?.odds_american === max_val_MoneyLine)
  // return JSON.stringify(cell);
  return (
    <td key={index}>
      <div className={styles.FlexConatiner}>
        <div className={styles.Box}>
          <span>{selected_book?.odds_american || "N/A"}</span>
        </div>
        <div className={styles.Box}>
          <img width={18} height={18} src={books[selected_book.book_name]} alt="Bookmaker Icon" />
          <span>{selected_book?.odds_american}</span>
        </div>
      </div>
    </td>
  );
}

export function playerOddsFormatter(cell: any, index: number, setPicks: any) {
  // let market = cell?.book_details.find((ele) => ele.id == "sr:market:225")

  let max_val_MoneyLine = _.max(cell?.book_details.map(ele => ele?.odds_american));
  let selected_book = cell?.book_details.find(ele => ele?.odds_american === max_val_MoneyLine)
  // return JSON.stringify(cell);
  return (
    <td key={index}>
      <div className={styles.FlexConatiner}>
        <div className={styles.Box} onClick={() => setPicks(formatPicks(cell?.market_name, cell))}>
          <img width={18} height={18} src={books[selected_book.book_name]} alt="Bookmaker Icon" />
          <span>{selected_book?.odds_american || "N/A"} </span>
        </div>
      </div>
    </td>
  );
}

export function PointSpreadFormatter(cell: any, index: number) {
  let outcomes = cell?.markets.find(ele => ele.id == 'sr:market:223').books[1].outcomes;
  //  return JSON.stringify(outcome)
  return (<td key={index}>
    <>
      <div className={styles.pointspread}>
        <div className={styles.Box1}>
          <span>{outcomes[0]?.odds_american || 'N/A'}</span>
        </div>
        <div className={styles.Boxx}>
          <img width={18} height={18} src={Fanduel} alt="Bookmaker Icon" />
          <span>{outcomes[0]?.odds_american}</span>
        </div>
      </div>

      <div className={styles.pointspread}>
        <div className={styles.Box2}>
          <span>{outcomes[1]?.odds_american || 'N/A'}</span>
        </div>

        <div className={styles.Boxx}>
          <img width={18} height={18} src={Fanduel} alt="Bookmaker Icon" />

          <span>{outcomes[1]?.odds_american}</span>
        </div>
      </div>
    </>
  </td>
  );
}
export function MoneyLineFormatter(cell: any, index: number) {
  // return JSON.stringify(cell);
  return (<td key={index}>
    <>
      <div className={styles.Box}>
        <img width={18} height={18} src={Fanduel} alt="Bookmaker Icon" />
        <span>{cell?.markets[0]?.books[1]?.outcomes[0]?.odds_american}</span>
      </div>

      <div className={styles.Box}>
        <img width={18} height={18} src={Fanduel} alt="Bookmaker Icon" />
        <span>{cell?.markets[0]?.books[1]?.outcomes[1]?.odds_american}</span>
      </div>
    </>
  </td>
  );
}

export function TeamFormatter(cell: any, index: number) {
  console.log('here', cell?.sport_event?.competitors[0]?.name)
  return (<td key={index}>
    <span>
      {cell?.sport_event?.competitors[0]?.name} <br />
      {cell?.sport_event?.competitors[1]?.name}
    </span>
  </td>
  );
}

export function PlayerNameFormatter(cell: any, index: number) {
  const playerName = cell?.player_name.replaceAll(' ', '_');
  const imageUrl = `${PlayerImage_URL}${playerName}.png`;

  const handleError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.onerror = null; // to prevent infinite loop in case of repeated error
    event.currentTarget.src = 'https://i.pinimg.com/originals/07/66/d1/0766d183119ff92920403eb7ae566a85.png';
  };

  return (
    <td key={index}>
      <div className={styles.playernameContainer}>
        <img
          width={40}
          height={40}
          src={imageUrl}
          alt=""
          onError={handleError}
          style={{ marginRight: '5px' }}
        />
        <span>{cell?.player_name}</span>
      </div>
    </td>
  );
}

export function TeamNameFormatter(cell: any, index: number) {

  // console.log('playerdata',(cell));
  return (
    <td key={index}>
      <div className={styles.teamnameContainer}>
        <img width={30} height={30} src={`${Image_URL}${cell?.event_id?.competitors[0]?.name.replaceAll(' ', '_')}.svg`} alt="Bookmaker Icon" />
        <span>{cell?.event_id?.competitors[0]?.name}</span>
      </div>
    </td>
  );
}

export function SeasonFormatter(cell: any, index: number) {
  const percentage = cell?.last_matches_info?.last_season_pect;
  const backgroundColor = getBackgroundColor(percentage);

  return (
    <td key={index} className={styles.season} style={{ backgroundColor }}>
      <span style={{ color: getTextColor(percentage) }}>
        {percentage ? `${Math.round(percentage)}%` : '0%'}
      </span>
    </td>
  );
}

export function L5Formatter(cell: any, index: number) {
  const percentage = cell?.last_matches_info?.last_5_pect;
  const backgroundColor = getBackgroundColor(percentage);

  return (
    <td key={index} className={styles.L5} style={{ backgroundColor }}>
      <span style={{ color: getTextColor(percentage) }}>
        {percentage ? `${percentage}%` : 'O%'}
      </span>
    </td>
  );
}

export function L10Formatter(cell: any, index: number) {
  const percentage = cell?.last_matches_info?.last_10_pect;
  const backgroundColor = getBackgroundColor(percentage);

  return (
    <td key={index} className={styles.L10} style={{ backgroundColor }}>
      <span style={{ color: getTextColor(percentage) }}>
        {percentage ? `${percentage}%` : '0%'}
      </span>
    </td>
  );
}

function getBackgroundColor(percentage: number | undefined): string {
  if (percentage === undefined) {
    return ''; // You can set a default background color here
  } else if (percentage < 40) {
    return '#FFB2B2'; // Light red
  } else if (percentage >= 50) {
    return '#C8E6C9'; // Light green
  } else {
    return '#FFF59D'; // Yellow
  }
}

function getTextColor(percentage: number | undefined): string {
  return percentage !== undefined && percentage < 40 ? '#8B0000' : percentage !== undefined && percentage >= 50 ? '#006400' : '#8B8000' ; 
}

export function L30Formatter(cell: any, index: number) {
  // return JSON.stringify(cell);
  return <td key={index} className={styles.L30}>
    <span>{cell.L30 ? (cell.L30 + "%") : 'N/A'}</span>
  </td>
}

const Formatter = () => {
  return <>Formatter</>
}

export default Formatter;