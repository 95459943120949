import React, { useState } from "react";
import { Button, FormControl, Checkbox, InputLabel, MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface DropdownProps {
  label: any;
  selectedValues: any;
  handleChange: any;
  handleClear: any;
  itemList: any;
  width?: any;
  flag?: any
}

const CustomSelect = ({ label, selectedValues, handleChange, handleClear, itemList, width='150px', multiple = false, flag = 1}) => {
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedItemLabel, setSelectedItemLabel] = useState([]);

  const generateDisplayText = () => {
    if(flag===1) return selectedValues.map(item => item).join(', ');
    if(flag===3) return selectedItemLabel.map(item => item).join(', ');
    return selectedItem;
  };

  const handleItemClick = (item) => {
    // Check if the item already exists in selectedItemLabel
    if (selectedItemLabel.includes(item)) {
      // If it exists, remove it
      setSelectedItemLabel(selectedItemLabel.filter(selectedItem => selectedItem !== item));
    } else {
      // If it doesn't exist, add it
      setSelectedItemLabel([...selectedItemLabel, item]);
    }
  };
  

  const clearItemClick = (item) => {
    setSelectedItemLabel([]);
    handleClear();
  };
  
  return (
    <FormControl style={{ marginLeft: '20px' }}>
      <InputLabel
        style={{
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          marginTop: selectedValues.length > 0 ? '10px' : '0px',
        }}
      >
        {label}
        <ExpandMoreIcon style={{ marginLeft: '5px' }} />
        {selectedValues.length > 0 && <Button onClick={clearItemClick}>Clear</Button>}
      </InputLabel>
      <Select
        multiple={multiple}
        value={selectedValues}
        onChange={handleChange}
        style={{ minWidth: width, backgroundColor: 'black', color: 'white' }}
        MenuProps={{ 
          PaperProps: { style: { backgroundColor: '#222222' } },
          MenuListProps: { style: { color: 'white' } } 
         }}
        renderValue={() => generateDisplayText()}
      >
        {itemList.map((item) => (
          flag===1 ?
          (
            <MenuItem key={item} value={item}>
              <Checkbox checked={selectedValues.includes(item)} style={{ color: 'white' }} />
              {item}
            </MenuItem>
          ) : 
          flag===2 ?
          (
            <MenuItem key={item} value={item} onClick={() => setSelectedItem(item)} style={{ color: 'white' }}>
              {item}
            </MenuItem>
          ) : 
          (
            <MenuItem key={item.value} value={item.value} onClick={() => handleItemClick(item.label)}>
              <Checkbox checked={selectedValues.includes(item.value)} style={{ color: 'white' }} />
              {item.label}
            </MenuItem>
          )
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
