import { DateFormatter, TeamFormatter, MoneyLineFormatter, PointSpreadFormatter, playerOddsFormatter, UnderFormatter, TeamNameFormatter, SeasonFormatter, L5Formatter, L10Formatter, L30Formatter, PlayerNameFormatter} from "./formatter";
  
export const columnHeadersPlayer = [
    {Title: "Name", key: PlayerNameFormatter},
    {Title: "Team", key: TeamNameFormatter},
    {Title: "Market", key: "market_name"},
    {Title: "Over/Under", key: "outcome_type"},
    {Title: "Line", key: "line_value"},
    {Title: "Odds", key: playerOddsFormatter},
    {Title: "2023", key: SeasonFormatter},
    {Title: "L5", key: L5Formatter},
    {Title: "L10", key: L10Formatter},
  ];