import logo from "./logo.svg";
import "./App.css";
import Layout from "./appLayout";
import "./assets/styles/global.css";
import { Provider } from "react-redux";
import { AppRoutes } from "./routes";
import { createTheme, ThemeProvider , useTheme} from "@mui/material/styles";
import defaultTheme from "@mui/material/styles/defaultTheme";
import { lime, purple } from "@mui/material/colors";
import { deepmerge } from '@mui/utils';
import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
// const theme = createTheme(deepmerge(defaultTheme,{
//   palette: {
//     primary: {
//       main: "#FF5733",
//     },
//     text:{
//       icon: "#FF5733"
//     }
//   }
// }));

// console.log('theme',theme)
function App() {

  return (
    <>
      {/* <ThemeProvider theme={theme}> */}
      <BrowserRouter>
          <AppRoutes/>
        </BrowserRouter>
      {/* </ThemeProvider> */}
    </>
  );
}

export default App;
