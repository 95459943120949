
import React, { type ReactElement, useState, useEffect } from "react";
import styles from "./otpInputs.module.scss";
import { verifyCode } from "../../services/auth.aws.service";
import { alertService } from "../../services/alert.service";
import useQueryParams from "../../hooks/useQueryParams";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components";
// Our parent component
const OTPInputGroup = (): ReactElement => {
  // state to store all input boxes
  const navigate = useNavigate();
  const searchParams = useQueryParams();
  const email = decodeURI(searchParams["email"]|| "").replace(" ", "+");
  const [errorMessage,setErrorMessage] = useState('')
  const [inputValues, setInputValues] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
    input6: "",
    // Add more input values here
  });
  const [loading, setLoading] = useState(false); // State to track loading

  // this function updates the value of the state inputValues
  const handleInputChange = (inputId: any, value: any): void => {
    setInputValues((prevInputValues: any) => ({
      ...prevInputValues,
      [inputId]: value,
    }));
  };
  // this function processes form submission
  const handleSubmit = (): void => {
    // ... Your submit logic here
  };

  const handleSubmitOtp = async () => {
    console.log("here");
    setLoading(true); // Set loading to true when starting verification

   let val = await verifyCode(
      "email",
      { email, code: Object.values(inputValues).join("") },
      (err: any, res: any) => {
        setLoading(false); // Set loading to false when verification is done
        console.log("err", err);
        if (err) {
          alertService.error(err.message, { id: "top" });
          setErrorMessage('Invalid OTP');
          return;
        }

        console.log("here verify", res);

        alertService.success("Successfully Verified", { id: "top" });
        setTimeout(()=>{ navigate(encodeURI(`/`)) },1000)
      },
    );
    console.log("serachParams", searchParams);
    console.log("val", val);
    
  };

  useEffect(()=>{},[errorMessage]);
  // return child component
  return (
    <div className={styles.app}>
      <p className={styles.otptext}>OTP</p>
      <p className={styles.etext}>we have sent an otp to your email : </p>

      <div
        id="OTPInputGroup"
        className={styles.digitGroup}
        data-autosubmit="true"
      >
        <OTPInput
          id="input1"
          value={inputValues.input1}
          onValueChange={handleInputChange}
          previousId={null}
          handleSubmit={handleSubmit}
          nextId="input2"
        />
        <OTPInput
          id="input2"
          value={inputValues.input2}
          onValueChange={handleInputChange}
          previousId="input1"
          handleSubmit={handleSubmit}
          nextId="input3"
        />
        <OTPInput
          id="input3"
          value={inputValues.input3}
          onValueChange={handleInputChange}
          previousId="input2"
          handleSubmit={handleSubmit}
          nextId="input4"
        />

        <OTPInput
          id="input4"
          value={inputValues.input4}
          onValueChange={handleInputChange}
          previousId="input3"
          handleSubmit={handleSubmit}
          nextId="input5"
        />
        <OTPInput
          id="input5"
          value={inputValues.input5}
          onValueChange={handleInputChange}
          previousId="input4"
          handleSubmit={handleSubmit}
          nextId="input6"
        />
        <OTPInput
          id="input6"
          value={inputValues.input6}
          onValueChange={handleInputChange}
          previousId="input5"
          handleSubmit={handleSubmit}
        />
      </div>
      <p className={styles.resend}>{errorMessage}</p>
      {/* <p className={styles.resend}>Resend OTP</p> */}
      {loading && <Loading/>} {/* Display loading indicator if loading is true */}
      <div className={styles.btnGroup} onClick={handleSubmitOtp}>
        <button className={styles.button}>Confirm</button>
      </div>
    </div>
  );
};

// Our child component

export interface OTPInputProps {
  id: string;
  previousId: any;
  nextId?: any;
  value: any;
  onValueChange: any;
  handleSubmit: any;
}

function OTPInput({
  id,
  previousId,
  nextId,
  value,
  onValueChange,
  handleSubmit,
}: OTPInputProps): React.JSX.Element {
  // This callback function only runs when a key is released
  const handleKeyUp = (e: any): void => {
    // check if key is backspace or arrowleft
    if (e?.keyCode === 8 || e?.keyCode === 37) {
      // find the previous element
      const prev = document.getElementById(previousId) as HTMLInputElement;
      if (prev != null) {
        // select the previous element
        prev.select();
      }
    } 
    else if (
      (e.keyCode >= 48 && e.keyCode <= 57) || // check if key is numeric keys 0 to 9
      (e.keyCode >= 96 && e.keyCode <= 105) // check if key is numeric keypad keys 0 to 9
    ) 
    {
      // find the next element
      const next = document.getElementById(nextId) as HTMLInputElement;
      if (next != null) {
        // select the next element
        next.select();
      } else {
        // check if inputGroup has autoSubmit enabled
        const inputGroup = document.getElementById("OTPInputGroup");
        if (inputGroup?.dataset?.autosubmit != null) {
          // submit the form
          handleSubmit();
        }
      }
    }
  };

  const handlePaste = (e) => {
    // Prevent the default paste behavior
    e.preventDefault();
    
    // Get the pasted text
    const pastedText = e.clipboardData.getData('text');
    
    // Check if pasted text is exactly 6 digits
    if (/^\d{6}$/.test(pastedText)) {
      // Update the input values for each digit
      for (let i = 0; i < pastedText.length; i++) {
        const key = `input${i + 1}`;
        onValueChange(key, pastedText[i]);
      }
      
      // If there's a next input field, focus on it
      const lastInputId = `input${pastedText.length}`;
      const lastInput = document.getElementById(lastInputId) as HTMLInputElement;
      if (lastInput) {
        lastInput.focus();
      }
    }
  };
  

  return (
    <input
      id={id}
      name={id}
      type="text"
      value={value}
      maxLength={1}
      onChange={(e) => onValueChange(id, e.target.value.replace(/\D/g, ''))} //// Filter out non-digit characters
      onKeyUp={handleKeyUp}
      onPaste={handlePaste}
    />
  );
}

export default OTPInputGroup;
