import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface MyPicksState {
  picks: any;
  isOpenPicks: boolean;
  selectedBook: string;
}

const initialState: MyPicksState = {
  picks: [],
  isOpenPicks: false,
  selectedBook: "FanDuel",
};

export const myPicksSlice = createSlice({
  name: "mypicks",
  initialState,
  reducers: {
    addPicks: (state, action: PayloadAction<any>) => {
      console.log(`State is`, state, ` and action is`, action);
      const newPick = action.payload;
      const found = state.picks.some((p) => p.id === newPick?.id);
      if (!found) {
        if (
          newPick?.isTeam &&
          newPick?.books?.some((book) => book?.name === state.selectedBook)
        )
          newPick["isAvailable"] = true;
        else if (
          newPick?.books?.some((book) => book?.book_name === state.selectedBook)
        )
          newPick["isAvailable"] = true;
        else newPick["isAvailable"] = false;

        state.picks = [...state.picks, newPick];
      }
    },
    deletePick: (state, action: PayloadAction<any>) => {
      const filteredlist = state.picks.filter(
        (ele) => ele.id !== action.payload,
      );
      state.picks = [...filteredlist];
    },
    openPicks: (state) => {
      state.isOpenPicks = true;
    },
    closePicks: (state) => {
      state.isOpenPicks = false;
    },
    resetPicks: (state) => {
      state.picks = [];
    },
    selectBook: (state, action: PayloadAction<any>) => {
      const picks = state.picks;
      const updatedPicks = picks?.map((pick) => ({
        ...pick,
        isAvailable:
          (pick?.isTeam &&
            pick?.books?.some((book) => book?.name === action.payload)) ||
          pick?.books?.some((book) => book?.book_name === action.payload)
            ? true
            : false,
      }));
      state.picks = updatedPicks;
      state.selectedBook = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addPicks,
  deletePick,
  resetPicks,
  openPicks,
  closePicks,
  selectBook,
} = myPicksSlice.actions;

export default myPicksSlice;
