import React, {
  createContext,
  useMemo,
  useState,
  useSyncExternalStore,
} from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import styles from "./assets/styles/layout.module.scss";
import { ToggleButton, ToggleButtonSwitch } from "./components";
import { Toggle } from "./components/Toggle";
import { Searchbar } from "./components/Searchbar";
import { Nb } from "./components/Nb";
import { AppRoutes } from "./routes";
import { Provider } from "react-redux";
import store from "./store";
const Layout = ({ children }) => {
  const { pathname } = useLocation();
  
  const activeNavClass = (route) =>
    pathname === route ? styles.navActive : styles.navText;
  const activeButtonWrapper = (route) => {
    switch (route) {
      case "/insights":
        return (
          <ToggleButtonSwitch
            buttons={{ all: "All", players: "Players", teams: "Teams" }}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      
      <div>
        <Nb></Nb>
      </div>
      <div className={styles.AppContainer}>
        <div>
          <Toggle></Toggle>
        </div>

        <div className={`${styles.navContainer} ${styles.m10}`}>
          <div className={styles.item}>
            <Link to="/insights" className={activeNavClass("/insights")}>
              <div>Insights</div>
            </Link>
          </div>
          <div className={styles.item}>
            <Link to="/matchups" className={activeNavClass("/matchups")}>
              <div>Matchups</div>
            </Link>
          </div>
          <div className={styles.item}>
            <Link to="/players" className={activeNavClass("/players")}>
              <div>Players</div>
            </Link>
          </div>
        </div>
        {/* <div className={`${styles.title} ${styles.item}`}>
            {activeHeader(currentRoute)}
          </div> */}
        <div className={`${styles.item} ${styles.rowReverse}`}>
          {activeButtonWrapper(pathname)}
        </div>

        {/* <Searchbar></Searchbar> */}
        <Outlet key={Date.now()} />
      </div>
    </>
  );
};

export default Layout;
