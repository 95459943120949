import React, { useEffect, useState } from "react";
import { CardSquareItem, SportsDropdown } from "../../components";
import { fetchNews } from "../../services/matchups.service";
import styles from "./news.module.scss"

export interface newsProps {
  prop?: string;
}

function News({ prop}: newsProps): React.JSX.Element {
  const [news, setNews] = useState([]);
  const [newsFetch, setNewsFetch] = useState(false);
  useEffect(()=>{
     fetchNews((data: any)=> {setNews(data); setNewsFetch(true)});
  },[])
  return (
      <div className={styles.CardSquareContainer}>
      {news.map((ele: any,index: number) => <CardSquareItem key={index} {...ele}/>)}
      </div>
  );
}

export default News;
