import { fetchAuthSession } from 'aws-amplify/auth';
import fsPromises from 'fs/promises';
import path from 'path'

export function fetchTeams(callback: Function) {
  const token = localStorage.getItem("access_token")
    fetch(
        "https://devapi.bettics.win/api/bettics/v1/nba/team-props",
        {
          headers: {
            'Authorization': token
          }
        }
      )
        // .then((response) => response.json())
        .then((response) => {
          fetchAuthSession({forceRefresh: true})
          if (response.status === 401) {
            // Token expired or unauthorized, clear localStorage
            localStorage.clear();
            sessionStorage.clear();
            window.location.reload();
          }
          return response.json();
        })
        .then((details) => {
          callback(details.teamProps);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });   
}



// export function fetchPlayers(callback: Function){
//     fetch("./playerData.json")
//         .then((response) => response.json())
//         .then((details) => {
//           details = details.map((item: any) => ({
//             ...item,
//             commence_time: new Date(item.commence_time).toLocaleString(),
//           }));
//           callback(details);
//         })
//         .catch((error) => {
//           console.error("Error fetching data:", error);
//         });
// }

// export async function fetchTeams(callback: Function){
//   let response = (await import("./teamJson")).default;
//   callback(response)
// }
export async function fetchPlayers(callback: Function){
    let response = (await import("./playerData")).default;
    callback(response.data)
}

export async function fetchInsights(callback: Function){
  let response = (await import("./insightsData")).default;
  callback(response)
}
export async function fetchNews(callback: Function){
  let response = (await import("./newsData")).default;
  callback(response)
}