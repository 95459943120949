import {
  DateFormatter,
  TeamFormatter,
  MoneyLineFormatter,
  PointSpreadFormatter,
  OverFormatter,
  UnderFormatter,
  TeamNameFormatter,
  SeasonFormatter,
  L5Formatter,
  L10Formatter,
  L30Formatter,
} from "./formatter";

export const columnHeadersTeam = [
  { Title: "Date", key: DateFormatter },
  { Title: "Team", key: TeamFormatter },
  { Title: "Moneyline", key: MoneyLineFormatter },
  { Title: "Spread", key: PointSpreadFormatter },
  { Title: "Over", key: OverFormatter },
  { Title: "Under", key: UnderFormatter },
];

export const columnHeadersPlayer = [
  { Title: "Name", key: "name" },
  { Title: "Team", key: TeamNameFormatter },
  { Title: "Line", key: "line" },
  { Title: "Over", key: "over" },
  { Title: "Under", key: "under" },
  { Title: "Season", key: SeasonFormatter },
  { Title: "L5", key: L5Formatter },
  { Title: "L10", key: L10Formatter },
  { Title: "L30", key: L30Formatter },
];

export const FANDUEL_URL =
  "https://account.sportsbook.fanduel.com/sportsbook/addToBetslip";
export const DRAFTKING_URL = "https://sportsbook.draftkings.com/event";
export const MGM_URL = "https://sports.betmgm.com/en/sports/events";
