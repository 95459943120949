import { fetchAuthSession } from "aws-amplify/auth";

export function fetchPlayers(page: number, players: string[], markets: string[], outcomes: string, schedules: string[], l5: string, l10: string, lSeason: string, callback: Function) {
  const token = localStorage.getItem("access_token");
  const playersQuery = players.length > 0 ? `&players=${players.join(",")}` : "";
  const marketsQuery = markets.length > 0 ? `&markets=${markets.join(",")}` : "";
  const outcomesQuery = outcomes.length > 0 ? `&outcome_type=${outcomes}` : "";
  const schedulesQuery = schedules.length > 0 ? `&schedules=${schedules.join(",")}` : "";
  
  let ascendingQuery = `&asc_sort=`;
  let descendingQuery = `&desc_sort=`;
  
  if(l5==='asc') ascendingQuery += 'last_5_pect,';
  else if(l5==='desc') descendingQuery += 'last_5_pect,';

  if(l10==='asc') ascendingQuery += 'last_10_pect,';
  else if(l10==='desc') descendingQuery += 'last_10_pect,';

  if(lSeason==='asc') ascendingQuery += 'last_season_pect,';
  else if(lSeason==='desc') descendingQuery += 'last_season_pect,';

  if (ascendingQuery[ascendingQuery.length - 1] === ',') {
    ascendingQuery = ascendingQuery.slice(0, -1); // Removing the last comma
  }
  if (descendingQuery[descendingQuery.length - 1] === ',') {
    descendingQuery = descendingQuery.slice(0, -1); // Removing the last comma
  }
  if(ascendingQuery.length===10) ascendingQuery='';
  if(descendingQuery.length===11) descendingQuery='';

  console.log(`https://devapi.bettics.win/api/bettics/v1/nba/player-props?page=${page}${playersQuery}${marketsQuery}${outcomesQuery}${ascendingQuery}${descendingQuery}`);

  fetch(
    `https://devapi.bettics.win/api/bettics/v1/nba/player-props?page=${page}${playersQuery}${marketsQuery}${outcomesQuery}${schedulesQuery}${ascendingQuery}${descendingQuery}`,
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status === 401) {
        // Token expired or unauthorized, clear localStorage
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
      }
      return response.json();
    })
    .then((details) => {
      const playerData = details.playerProps;
      const hasMoreData = details.hasMore; // Assuming the API response includes 'hasMore'

      callback(playerData, hasMoreData);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
}



// players.service.ts
export async function fetchFilters() {
  const token = localStorage.getItem("access_token");
  try {
    const response = await fetch(
      "https://devapi.bettics.win/api/bettics/v1/nba/filters",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    fetchAuthSession({forceRefresh: true})

    if (response.status === 401) {
      // Token expired or unauthorized, clear localStorage
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }

    const filtersData = await response.json();
    return filtersData;
  } catch (error) {
    console.error("Error fetching filters:", error);
    throw error;
  }
}

export function fetchPlayerDropdown(players: string[], markets: string[], outcomes: string, schedules: string[], callback: Function) {
  const token = localStorage.getItem("access_token");
  const playersQuery = players.length > 0 ? `&players=${players.join(",")}` : "";
  const marketsQuery = markets.length > 0 ? `&markets=${markets.join(",")}` : "";
  const outcomesQuery = outcomes.length > 0 ? `&outcome_type=${outcomes}` : "";
  const schedulesQuery = schedules.length > 0 ? `&schedules=${schedules.join(",")}` : "";

  let descendingQuery = `&desc_sort=last_5_pect,last_10_pect,last_season_pect,outcome_type`;

  console.log(`https://devapi.bettics.win/api/bettics/v1/nba/player-props?page=1${playersQuery}${marketsQuery}${outcomesQuery}${schedulesQuery}${descendingQuery}`);

  fetch(
    `https://devapi.bettics.win/api/bettics/v1/nba/player-props?page=1${playersQuery}${marketsQuery}${outcomesQuery}${schedulesQuery}${descendingQuery}`,
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      fetchAuthSession({forceRefresh: true})
      if (response.status === 401) {
        // Token expired or unauthorized, clear localStorage
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
      }
      return response.json();
    })
    .then((details) => {
      const playerData = details.playerProps;
      const hasMoreData = details.hasMore; // Assuming the API response includes 'hasMore'

      callback(playerData, hasMoreData);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
}
