import { createSlice } from "@reduxjs/toolkit";

export const toggleButtonSlice = createSlice({
    name: "toggleButton",
    initialState: { isActive: false},
    reducers: {
        setIsActive: (state: any, action: any) => {
            console.log(`toggle`, state, action)
            state.isActive = action.payload.isActive;
        }           
    }
})

export const toggleSwitchButtonSlice = createSlice({ 

    name: "toggleSwitchButton",
    initialState: { value: 'All'},
    reducers: {
        setValue: (state: any, action: any) => {
            console.log(`toggleSwitch`, state, action)
            state.value = action.payload.value;
        }
    }
    
})


export const toggleButtonActions = toggleButtonSlice.actions;
export const toggleSwitchButtonActions = toggleSwitchButtonSlice.actions;
