import React from 'react';

import styles from './CardSquareItem.module.scss';
import moment from 'moment';
import styled from '@emotion/styled';

export interface CardSquareItemProps {
  description: string,
  postedAt: any,
  image: any,
  imageAlt: string,
  link: string,
}

export function CardSquareItem({image, imageAlt, link, postedAt, description}: CardSquareItemProps) {
  const timeText = moment(postedAt).startOf('hour').fromNow();
  return <div>
     <div className={styles.CardSquareItemContainer}>
        <div className={styles.ImageContainer}>
          <img src={image} alt={imageAlt}></img>
        </div>
        <div className={styles.TextContainer}>
          <div className={styles.descriptionText}>{description}</div>
          <div className={styles.InfoTextContainer}>
            <div className={styles.timeText}>{timeText} - </div>
            <div className={styles.linkText}><a href={link}><span>Watch Video</span></a></div>
          </div>
        </div>
     </div>
  </div>;
}
