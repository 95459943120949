import React, { Suspense, lazy } from "react";
import styles from "./TableComponent.module.scss";
import { Loading } from "../Loading";
import { RenderTableBody } from "./TableBody";
import { FaSortAmountUp, FaSortAmountDownAlt } from "react-icons/fa";
import { FaAlignLeft } from "react-icons/fa6";

export interface TableComponentProps {
  props?: any;
  columnHeaders: any;
  rows: any;
  fetch: boolean;
  openPicks?: any;
  setPicks?: any;
  setPicksV2?: any;
  l5?: any;
  setL5?: any;
  l10?: any;
  setL10?: any;
  lSeason?: any;
  setLSeason?: any;
}

export function TableComponent({
  columnHeaders,
  rows,
  fetch,
  openPicks,
  setPicks,
  setPicksV2,
  l5,
  setL5,
  l10,
  setL10,
  lSeason,
  setLSeason,
}: TableComponentProps) {
  const func = (col,setCol)=>{
      if (col === "") {
        setCol("asc");
      } else if (col === "asc") {
        setCol("desc");
      } else if (col === "desc") {
        setCol("");
      }
  };

  return (
    <div className={styles["table-container"]}>
      <table className={styles.table}>
        <thead>
          <tr className={styles["fixed-row"]}>
            {columnHeaders.map((item: any, index: number) => (
              <th key={index}>
                {item.Title}

                {item.Title === "L5" && (
                  <>
                    {l5 === "" ? (
                      <FaAlignLeft
                        style={{ marginLeft: "10px" }}
                        onClick={() => func(l5,setL5)}
                      />
                    ) : l5 === "asc" ? (
                      <FaSortAmountDownAlt
                        style={{ marginLeft: "10px" }}
                        onClick={() => func(l5,setL5)}
                      />
                    ) : (
                      <FaSortAmountUp
                        style={{ marginLeft: "10px" }}
                        onClick={() => func(l5,setL5)}
                      />
                    )}
                  </>
                )}

                {item.Title === "L10" && (
                  <>
                    {l10 === "" ? (
                      <FaAlignLeft
                        style={{ marginLeft: "10px" }}
                        onClick={() => func(l10,setL10)}
                      />
                    ) : l10 === "asc" ? (
                      <FaSortAmountDownAlt
                        style={{ marginLeft: "10px" }}
                        onClick={() => func(l10,setL10)}
                      />
                    ) : (
                      <FaSortAmountUp
                        style={{ marginLeft: "10px" }}
                        onClick={() => func(l10,setL10)}
                      />
                    )}
                  </>
                )}

                {item.Title === "2023" && (
                  <>
                    {lSeason === "" ? (
                      <FaAlignLeft
                        style={{ marginLeft: "10px" }}
                        onClick={() => func(lSeason,setLSeason)}
                      />
                    ) : lSeason === "asc" ? (
                      <FaSortAmountDownAlt
                        style={{ marginLeft: "10px" }}
                        onClick={() => func(lSeason,setLSeason)}
                      />
                    ) : (
                      <FaSortAmountUp
                        style={{ marginLeft: "10px" }}
                        onClick={() => func(lSeason,setLSeason)}
                      />
                    )}
                  </>
                )}

              </th>
            ))}
          </tr>
        </thead>

        <RenderTableBody
          rows={rows}
          columnHeaders={columnHeaders}
          fetch={fetch}
          openPicks={openPicks}
          setPicks={setPicks}
          setPicksV2={setPicksV2}
        />
      </table>
    </div>
  );
}
