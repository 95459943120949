import React, { } from "react";
import { Nb } from "../../components";
import Team from "../../components/Team/Team";

const Teams = () => {
    return ( 
        <div>   
            <Nb/>   
           <Team/>
         </div>
     );
}
 
export default Teams;