import { sessionStorage } from "aws-amplify/utils"
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito"
import { Amplify } from "aws-amplify"
import { confirmSignIn, signIn, signUp, fetchAuthSession } from "aws-amplify/auth"
// import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
      signUpVerificationMethod: 'code'
    },
  },
})

cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);

const currentConfig = Amplify.getConfig();


function getRandomString(bytes) {
    const randomValues = new Uint8Array(bytes)
    window.crypto.getRandomValues(randomValues)
    return Array.from(randomValues).map(intToHex).join("")
  }

  function intToHex(nr) {
    return nr.toString(16).padStart(2, "0")
  }

export const login = async (type: string, prop: { email: string }, callback:Function) => {
    // setLoading(true)
    const {email} = prop; 
    try {
      const { nextStep: nextStep2 } = await signIn({
        username: email,
        options: {
          authFlowType: "CUSTOM_WITHOUT_SRP",
        },
      })
      if (nextStep2.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE") {
        // setEmailSent(true)
      }
    } catch (e) {
      console.log("ERROR IN LOGIN: ", e)
      callback(e)
      const { nextStep } = await signUp({
        username: email,
        password: getRandomString(30),
        options: {
          userAttributes: {
            name: "",
          },
          autoSignIn: true,
        },
      })
      console.log("NEXT STEP: ", nextStep)
      if (nextStep.signUpStep === "COMPLETE_AUTO_SIGN_IN") {
        const { nextStep: nextStep2 } = await signIn({
          username: email,
          options: {
            authFlowType: "CUSTOM_WITHOUT_SRP",
          },
        })
        if (nextStep2.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE") {
        //   setEmailSent(true)
        }
      }
    } finally {
    //   setLoading(false)
    }
  }

  export const verifyCode = async ( type: string,
    prop: { email: string; code: string },
    callback: Function) => {
    // e.preventDefault()
    // setLoading(true)
    const authCode = prop.code; 
    try {
     let res =  await confirmSignIn({
        challengeResponse: authCode,
      })
      console.log("CONFIRM SIGNIN OUTPUT ")
      if(!res.isSignedIn) {
        callback("Invalid OTP",null);
        return
      }
      currentSession();
      callback(null,res);
    //   setIsAuth(true)
    } catch (error) {
        callback(error,null);
    } finally {
    //   setLoading(false)
    }
  }

  export async function currentSession() {
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};

       localStorage.setItem("isAuth", `authenticated`);
       localStorage.setItem("access_token", 'Bearer '+accessToken); 

    } catch (err) {
      console.log(err);
    }
  }

  // export async function currentSession() {
  //   const navigate = useNavigate();
  
  //   try {
  //     const { accessToken, idToken } = (await fetchAuthSession({ forceRefresh: true })).tokens ?? {};
  
  //     localStorage.setItem("isAuth", `authenticated`);
  //     localStorage.setItem("access_token", 'Bearer ' + accessToken);
  
  //   } catch (err) {
  //     console.error("Error refreshing token:", err);
  
  //     // Handle token refresh failure, e.g., redirect to sign-in page
  //     if (err.name === 'NotAuthorizedException' || err.name === 'UserNotConfirmedException') {
  //       // Redirect to the sign-in page
  //       navigate('/login');
  //     } else {
  //       // Other error, log and handle as needed
  //       console.error("Unhandled error during token refresh:", err);
  //     }
  //   }
  // }


  