import React, { } from "react";
import { useState } from 'react';
import classNames from "classnames";
import styles from './Toggle.module.scss'


export const Toggle = () => {
  const [activeOption, setActiveOption] = useState('NBA');

  const handleClick = (option:any) => {
    setActiveOption(option);
  };

  return (
    <div>
      <div className={styles.toggleContainer}>
        <button
     className={classNames(
        styles.toggleButton,
        { [styles.activeToggleButton]: activeOption === "NBA" }
      )}
      onClick={() => handleClick("NBA")}
        >
          NBA
        </button>
        <button
          className={styles.toggleButton2}
        >
          NFL
        </button>
        <button
          className={styles.toggleButton2}
        >
          NCAAFB
        </button>
        <button
           className={styles.toggleButton2}
        >
         WNBA
        </button>
        <button
           className={styles.toggleButton2}
        >
          NHL
        </button>
        <button
            className={styles.toggleButton2}
              onClick={() => handleClick("MLB")}
        >
         MLB
        </button>
        <button
           className={styles.toggleButton2}
        >
          NCAAMB
        </button>
      </div>

    </div>
  );
}

