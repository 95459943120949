import pfp from '../assets/png/kesley.png'

const data = {
    "data": [
     {
      name: "Kelsey Mitchell",
      team: "Indiana Fever",
      image: pfp,
      id:1,
   },
     {
      name: "Kelsey Mitchell",
      team: "Indiana Fever",
      image: pfp,
      id:2,
   }
   ,
     {
      name: "Kelsey Mitchell",
      team: "Indiana Fever",
      image: pfp,
      id:3,
   }
   
   ,
     {
      name: "Kelsey Mitchell",
      team: "Indiana Fever",
      image: pfp,
      id:4,
   }
   
   ,
     {
      name: "Kelsey Mitchell",
      team: "Indiana Fever",
      image: pfp,
      id:5,
   },
     {
      name: "Kelsey Mitchell",
      team: "Los Angeles Lakers",
      image: pfp,
      id:6,
   },
   {
    name: "Kelsey Mitchell",
    team: "Los Angeles Lakers",
    image: pfp,
    id:7,
 },
 {
  name: "Kelsey Mitchell",
  team: "Los Angeles Lakers",
  image: pfp,
  id:8,
},
{
 name: "Kelsey Mitchell",
 team: "Los Angeles Lakers",
 image: pfp,
 id:9,
},
{
 name: "Kelsey Mitchell",
 team: "Los Angeles Lakers",
 image: pfp,
 id:10,
}
   
   
   ]
   }

export default data;