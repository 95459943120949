import React from "react";
import moment from "moment";
import styles from "./matchups.module.scss";
import Stack from "@mui/material/Stack";
import _ from "lodash";
import books from "../../assets/png/books";
const Image_URL = "https://images.bettics.win/nba/team/"
const calculateSpread = (odd) => {
  return (odd + 100) / odd;
};

const formatPicks = (market,cell,teamType,isUnderOver = '') =>{
  if(isUnderOver == "Over" || isUnderOver == "Under"){
    let market$ = cell?.markets.find((ele) => ele.id == "sr:market:225")
    
    return {
      id:cell?._id+cell?.sport_event.competitors[teamType]?.id+isUnderOver,
      market: market,
      teamType,
      subject: `${isUnderOver} ${market$?.books[0]?.outcomes[0]?.total}`,
      books: cell?.markets[0]?.books,
      isTeam: true

    }
  }
    return {
      id:cell?._id+cell?.sport_event.competitors[teamType]?.id+market,
      market: market,
      teamType,
      subject: cell?.sport_event.competitors[teamType]?.name,
      books: cell?.markets[0]?.books,
      isTeam: true
    }
}



export function DateFormatter(cell: any, index: number) {
  return (
    <td key={index}>
      <>
        <b>{moment(cell.sport_event.start_time).format('MMMM Do')}</b>
        <br />
        <time>{moment(cell.sport_event.start_time).format('h:mm A')}</time>
      </>
    </td>
  );
}

export function UnderFormatter(cell: any, index: number,setPicks: any, openPicks:any, setPicksV2: any) {
  console.log("This is SetPicksV2",setPicksV2)
  let market = cell?.markets.find((ele) => ele.id == "sr:market:225")

  let max_val_MoneyLine = _.max(market?.books.map(ele=>ele?.outcomes[0]?.odds_american));
let selected_book = market?.books.find(ele=>ele?.outcomes[0]?.odds_american === max_val_MoneyLine )
  // return JSON.stringify(cell);
  return (
    <td key={index}>
      <div className={styles.FlexConatiner}>
        <div className={styles.Box}>
          <span>{selected_book?.outcomes[0]?.total || "N/A"}</span>
        </div>
        <div className={styles.Box} onClick={()=>{setPicksV2(formatPicks("Total O/U",cell,0,"Under"));openPicks()}}>
          <img width={18} height={18} src={books[selected_book?.name]} alt="Bookmaker Icon" />
          <span>{selected_book?.outcomes[0]?.odds_american}</span>
        </div>
      </div>
    </td>
  );
}

export function OverFormatter(cell: any, index: number,setPicks: any, openPicks:any,setPicksV2: any) {
  let market = cell?.markets.find((ele) => ele.id == "sr:market:225")

  let max_val_MoneyLine = _.max(market?.books.map(ele=>ele?.outcomes[0]?.odds_american));
let selected_book = market?.books.find(ele=>ele?.outcomes[0]?.odds_american === max_val_MoneyLine )
  // return JSON.stringify(cell);
  return (
    <td key={index}>
      <div className={styles.FlexConatiner}>
        <div className={styles.Box}>
          <span>{selected_book?.outcomes[1]?.total || "N/A"}</span>
        </div>
        <div className={styles.Box} onClick={()=>{setPicksV2(formatPicks("Total O/U",cell,0,"Over"));openPicks()}}>
          <img width={18} height={18} src={books[selected_book?.name]} alt="Bookmaker Icon" />
          <span>{selected_book?.outcomes[1]?.odds_american}</span>
        </div>
      </div>
    </td>
  );
}

export function PointSpreadFormatter(cell: any, index: number,setPicks: any, openPicks:any,setPicksV2: any) {
  
  let market = cell?.markets.find((ele) => ele.id == "sr:market:223")

    let max_val_MoneyLine = _.max(market?.books.map(ele=>ele?.outcomes[0]?.odds_american));
  let selected_book = market?.books.find(ele=>ele?.outcomes[0]?.odds_american === max_val_MoneyLine )
  console.log(cell?.markets);
  return (
    <td key={index}>
      <>
     {selected_book.outcomes.map((outcome)=> outcome.type === "home_handicap" &&( 
        <div className={styles.pointspread}>
          <div className={styles.Box1}>
            <span>{outcome?.handicap || "N/A"}</span>
          </div>
          <div className={styles.Boxx} onClick={()=>{setPicksV2(formatPicks("Spread",cell,0));openPicks()}}>
            <img width={18} height={18} src={books[selected_book.name]} alt="Bookmaker Icon" />
            <span>{selected_book.outcomes[0]?.odds_american}</span>
          </div>
        </div>
        ))}
        {selected_book.outcomes.map((outcome)=> outcome.type === "away_handicap" &&
        (<div className={styles.pointspread}>
          <div className={styles.Box2}>
            <span>{outcome?.handicap || "N/A"}</span>
          </div>

          <div className={styles.Boxx} onClick={()=>{setPicksV2(formatPicks("Spread",cell,1));openPicks()}}>
            <img width={18} height={18} src={books[selected_book.name]} alt="Bookmaker Icon" />

            <span>{selected_book.outcomes[1]?.odds_american}</span>
          </div>
        </div>
     ))}
     </>
    </td>
  );
}
export function MoneyLineFormatter(cell: any, index: number, setPicks: any, openPicks:any,setPicksV2: any) {
  console.log("setPicks",setPicks, "openPicks",openPicks);
  let max_val_MoneyLine = _.max(cell?.markets[0]?.books.map(ele=>ele?.outcomes[0]?.odds_american));
  let selected_book = cell?.markets[0]?.books.find(ele=>ele?.outcomes[0]?.odds_american === max_val_MoneyLine )
  // return JSON.stringify(cell);
  console.log('selected books',selected_book);
  return (
    <td key={index}>
      <>
        <div className={styles.Box} onClick={()=>{setPicksV2(formatPicks("Moneyline",cell,0));openPicks()}}>
          <img width={18} height={18} src={books[selected_book.name]} alt="Bookmaker Icon" />
          <span>{selected_book?.outcomes[0]?.odds_american}</span>
        </div>

        <div className={styles.Box} onClick={()=>{setPicksV2(formatPicks("Moneyline",cell,1));openPicks()}}>
          <img width={18} height={18} src={books[selected_book.name]} alt="Bookmaker Icon" />
          <span>{selected_book?.outcomes[1]?.odds_american}</span>
        </div>
      </>
    </td>
  );
}

export function TeamFormatter(cell: any, index: number) {
  console.log("here", cell?.sport_event?.competitors[0]?.name);
  return (
    <td key={index}>
      <Stack direction="column" spacing={0} alignItems="center">
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
          <img
          width={30}
          height= {30}
            src={`${Image_URL}${cell?.sport_event?.competitors[0]?.name.replaceAll(' ','_')}.svg`}
            alt={cell?.sport_event?.competitors[0]?.name}
          />
          <span>{cell?.sport_event?.competitors[0]?.name}</span>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
        <img
         width={30}
         height= {30}
            src={`${Image_URL}${cell?.sport_event?.competitors[1]?.name.replaceAll(' ','_')}.svg`}
            alt={cell?.sport_event?.competitors[1]?.name}
          />
          <span>{cell?.sport_event?.competitors[1]?.name}</span>
        </Stack>
      </Stack>
    </td>
  );
}

export function TeamNameFormatter(cell: any, index: number) {
  // return JSON.stringify(cell);
  return (
    <td key={index}>
      <div className={styles.teamnameContainer}>
        <img width={18} height={18} src={books.FanDuel} alt="Bookmaker Icon" />
        <span>{cell.team}</span>
      </div>
    </td>
  );
}

export function SeasonFormatter(cell: any, index: number) {
  // return JSON.stringify(cell);
  return (
    <td key={index} className={styles.season}>
      <span>{cell.season}%</span>
    </td>
  );
}

export function L5Formatter(cell: any, index: number) {
  // return JSON.stringify(cell);
  return (
    <td key={index} className={styles.L5}>
      <span>{cell.L5}%</span>
    </td>
  );
}
export function L10Formatter(cell: any, index: number) {
  // return JSON.stringify(cell);
  return (
    <td key={index} className={styles.L10}>
      <span>{cell.L10}%</span>
    </td>
  );
}
export function L30Formatter(cell: any, index: number) {
  // return JSON.stringify(cell);
  return (
    <td key={index} className={styles.L30}>
      <span>{cell.L30}%</span>
    </td>
  );
}

const Formatter = () => {
  return <>Formatter</>;
};

export default Formatter;
