import React, { useEffect, useState } from "react";
import styles from "./insights.module.scss";
import withPrivateRoute from "../../lib/withPrivateRoute";
import { useSelector } from "react-redux";
import { CardItem, Loading, ToggleButton } from "../../components";
import { fetchInsights } from "../../services/matchups.service";


export interface insightsProps {
  prop?: any;
}

function Insights(prop: insightsProps): React.JSX.Element {
  const [insights, setInsights] = useState([]);
  const [insightsFetch, setInsightsFetch] = useState(false);
  const { value } = useSelector((state: any) => state.toggleSwitchButton);
  useEffect(() => {
    fetchInsights((data: any) => { setInsights(data); setInsightsFetch(true) });
  }, [])
  return (
    <>
      {/* {!insightsFetch && <Loading />}
      <div className={styles.CardItemContainer}>
        {insights.map((ele: any, index: number) => <CardItem data={ele} key={index} />)}
      </div> */}
      <div className={styles.centerContent}>
        COMING<br/>SOON
      </div>
    </>
  );
}
export default Insights;
