import { configureStore } from '@reduxjs/toolkit'
import { toggleButtonSlice, toggleSwitchButtonSlice } from './button.slice'
import mypicksSlice from './mypicks.slice'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
  key: 'mypicks',
  storage,
}

const persistedPicksReducer = persistReducer(persistConfig, mypicksSlice.reducer);
// config the store 

export const store= configureStore({
   reducer: {
      toggleButton : toggleButtonSlice.reducer,
      toggleSwitchButton : toggleSwitchButtonSlice.reducer,
      mypicks: persistedPicksReducer
   }
})

// export default the store 
export const persistor = persistStore(store)
