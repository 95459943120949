import React, { useEffect, useState, useContext, lazy } from "react";
import styles from "./matchups.module.scss";
import withPrivateRoute from "../../lib/withPrivateRoute";
import { Button, Loading } from "../../components";
import { columnHeadersTeam, FANDUEL_URL, DRAFTKING_URL, MGM_URL } from "./constants";
import { fetchPlayers, fetchTeams } from "../../services/matchups.service";
import { useDispatch, useSelector } from "react-redux";

import { TableComponent } from "../../components/TableComponent";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import books from "../../assets/png/books";
import CardContent from "@mui/material/CardContent";
import Fab from "@mui/material/Fab";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Button as MButton } from "@mui/material";
import { Select, MenuItem } from '@mui/material';
import "./matchups.module.scss";
import { addPicks, deletePick, openPicks } from "../../store/mypicks.slice";
import MyPicks from "../my-picks/mypicks";
const drawerWidth = 400;

interface matchupsProps {
  prop?: any;
}

function Matchups(prop: matchupsProps): React.JSX.Element {
  const [data, setData] = useState([]);
  const [dataFetch, setDataFetch] = useState(false);
  const [openDwawer, setDrawer] = useState(false);
  const [book, setBook] = useState("FanDuel");
  // const [picks, setPicks] = useState([]);
  const [exterEventUrl, setExternalUrl] = useState("")
  const dispatch = useDispatch();
 

  useEffect(() => {
    fetchTeams((data: any) => {
      setData(data);
      setDataFetch(true);
    });
  }, []);


  const handleFanduelEvent = (events) => {
    let generatedParams = ""

    events?.forEach((event, index) => {
      if (index === 0) {
        const param = `?marketId[${index}]=${event?.external_market_id}&selectionId[${index}]=${event?.outcomes[event?.teamType]?.external_outcome_id}`
        generatedParams += param
      }
      else {
        const param = `&marketId[${index}]=${event?.external_market_id}&selectionId[${index}]=${event?.outcomes[event?.teamType]?.external_outcome_id}`
        generatedParams += param
      }
    })

    setExternalUrl(FANDUEL_URL + generatedParams)
  }

  const handleDraftKingsEvent = (events) => {
    let generatedParams = `/${events[0]?.external_sport_event_id}`

    events?.forEach((event, index) => {
      if (index === 0) {
        const param = `?outcomes=${event?.outcomes[event?.teamType]?.external_outcome_id}`
        generatedParams += param
      }
      else {
        const param = `+${event?.outcomes[event?.teamType]?.external_outcome_id}`
        generatedParams += param
      }
    })

    setExternalUrl(DRAFTKING_URL + generatedParams)
  }

  const handleMgmEvent = (events) => {
    let generatedParams = `/${events[0]?.external_sport_event_id}`

    events?.forEach((event, index) => {
      if (index === 0) {
        const param = `?options=${event?.external_sport_event_id}-${event?.external_market_id}-${event?.outcomes[event?.teamType]?.external_outcome_id}`
        generatedParams += param
      }
      else {
        const param = `,${event?.external_sport_event_id}-${event?.external_market_id}-${event?.outcomes[event?.teamType]?.external_outcome_id}`
        generatedParams += param
      }
    })

    setExternalUrl(MGM_URL + generatedParams)
  }

  // const handleOpenBookEvent = () => {
  //   const bookEvents = []

  //   for (let pick of picks) {
  //     const requiredBook = pick?.books?.find(b => b?.name === book)
  //     if (requiredBook) {
  //       requiredBook["teamType"] = pick?.teamType
  //       bookEvents.push(requiredBook)
  //     }
  //   }

  //   if (book === "FanDuel")
  //     handleFanduelEvent(bookEvents)
  //   else if (book === "DraftKings")
  //     handleDraftKingsEvent(bookEvents)
  //   else if (book === "MGM")
  //     handleMgmEvent(bookEvents)

  // }

  const handleRemovePick = (pick) => {
     dispatch(deletePick(pick?.id))
  }

  return (<>
      <div className={styles.match}>
        <TableComponent
          columnHeaders={columnHeadersTeam}
          rows={data}
          fetch={dataFetch}
          openPicks={() => {
            setDrawer(true);
          }}
          // setPicks={(pick) => setPicks([pick, ...picks])}
          setPicksV2={(pick) => {
            dispatch(addPicks(pick));
            dispatch(openPicks());
          }}
        />
        <Fab
          onClick={() => setDrawer(true)}
          aria-label="like"
          sx={{ position: "fixed", bottom: 16, right: 16 }}
        >
          <FavoriteIcon />
        </Fab>
      </div>
      {/* <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            backgroundColor: "#262626",
          },
        }}
        variant="persistent"
        anchor="right"
        open={openDwawer}
      >
        <Toolbar style={{ color: "#fff", fontWeight: "bold" }}>
          My Picks
          <IconButton
            aria-label="delete"
            style={{ color: "#fff" }}
            onClick={() => setDrawer(false)}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Divider style={{ borderColor: "#fff" }} />
        <Select
          className={styles.MUIList}
          MenuProps={{ classes: { paper: styles.MUIList } }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={book}
          label="Age"
          onChange={(e) => { console.log('selected', e); setBook(e.target.value as string) }}
        >
          {["FanDuel", "DraftKings", "BetRivers", "MGM"].map((text, index) => (
            <MenuItem
              key={text}
              style={{
                display: "flex",
                paddingLeft: "20px",
                marginBottom: "10px",
                backgroundColor: "#222222"
              }}
              value={text}
            >
              <div style={{ display: "flex" }}>
                <ListItemIcon
                  style={{
                    alignSelf: "center",
                    minWidth: "fit-content",
                    marginRight: "10px ",
                    backgroundColor: "#222222"
                  }}
                >
                  <img width={18} height={18} src={books[text]} alt="Icon" />
                </ListItemIcon>
                <ListItemText style={{ color: "#fff" }} primary={text} />
              </div>
            </MenuItem>
          ))}
        </Select>
        <Divider style={{ borderColor: "#fff" }} />
        <List style={{ height: "70%", overflowY: "scroll" }}>
          {picks.map((pick, index) => (
            // <div style={{color:"#fff"}}>{JSON.stringify(pick)}</div>
            <ListItem key={index}>
              <Card
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  backgroundColor: "#5e5e5e",
                }}
              >
                <CardContent sx={{ flex: "1 0 auto", color: "#fff" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography component="div" variant="h6">
                      {pick.market}
                    </Typography>
                    <IconButton
                      aria-label="delete"
                      style={{ color: "#fff" }}
                      onClick={() => handleRemovePick(pick)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    {pick.subject}
                  </Typography>
                </CardContent>
                <Box
                  sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
                >
                  {pick.books.map((ele) => (
                    <ListItem
                      style={{}}
                      onClick={(e) => {
                        e.stopPropagation();
                        console.log("ele", ele);
                      }}
                    >
                      <ListItemButton>
                        <ListItemIcon
                          style={{
                            alignSelf: "center",
                            minWidth: "fit-content",
                            marginRight: "10px ",
                          }}
                        >
                          <img
                            width={18}
                            height={18}
                            src={books[ele.name]}
                            alt="Icon"
                          />
                        </ListItemIcon>

                        <ListItemText
                          style={{ color: "#fff" }}
                          primary={ele?.outcomes[pick?.teamType]?.odds_american}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </Box>
              </Card>
            </ListItem>
          ))}
        </List>
        <a
          style={{
            width: "100%",
            backgroundColor: "#FF7448",
            padding: "1rem",
            color: "#fff",
            position: "absolute",
            bottom: 0,
            pointerEvents: exterEventUrl ? "auto" : "none",
            textDecoration: "none",
            textAlign: "center",
            borderRadius: "2px"
          }}
          target="_blank"
          rel="noopener noreferrer"
          href={exterEventUrl}
        >
          Bet Picks
        </a>
      </Drawer> */}
      <MyPicks/>
      </>
  );
}
export default Matchups;
