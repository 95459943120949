import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./ToggleButtonSwitch.module.scss";
import { toggleSwitchButtonActions } from "../../store/button.slice";

export interface ToggleButtonSwitchProps {
  buttons?: any;
  default?: string;
  test?: boolean
}

export function ToggleButtonSwitch(prop: ToggleButtonSwitchProps) {
  let  { value }  = useSelector((state: any)=>state.toggleSwitchButton);
  const dispatch = useDispatch();
  const setActiveClass = (a: string,b:string) => {
    return `toggleSwitchButton-${a}` === `toggleSwitchButton-${b}` ? 'active': ''
  }
  const handleClick = (prop: any) => {
    dispatch(toggleSwitchButtonActions.setValue(prop))
  };

  const [showDesc, setShowDesc] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState('all');

  const toggleDesc = (index :any) => {
    setShowDesc(!showDesc);
    setSelectedIndex(index);
    
  };

  return (
    <div className={styles.ToggleButtonSwitchContainer}  style={{color:'white'}}>
      {/* {Object.keys(prop?.buttons).map((key: string, index: any) => (
        <div key={index} className={styles.ToggleButtonSwitch}>
          <button
            id={`toggleSwitchButton-${key} `}
            className={`${styles.toggleButton} ${setActiveClass(key,(!test?value : prop.default))}`}
            onClick={() => handleClick({value: key})}
          >
            {prop?.buttons[key]}
          </button>
        </div>
      ))} */}
  
     


   
    <div>
        <div className="dropdown bg-grey" id="dropdown1">
          <a className="btn btn-secondary dropdown-toggle bg-black p-2 border border-grey"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ fontSize: '0.8em', width: 'max-content' }}>
            <span style={{ marginRight: '6vw', width: 'max-content' }}>All</span>
          </a>

          <ul className="dropdown-menu bg-red">
            <li><a className="dropdown-item" href="#">test1</a></li>
            <li><a className="dropdown-item" href="#">test2</a></li>
            <li><a className="dropdown-item" href="#">test3</a></li>
          </ul>

        
        </div>
      </div>
   

    </div>

    
  );
}
